<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="dataList"
            :items-per-page="10"
            :options.sync="options"
            :server-items-length="totalLength"
            class="elevation-1"
            :header-props="{ sortIcon: '$vuetify.icons.arrow_top' }"
            :footer-props="{ prevIcon: '$vuetify.icons.previous_page', nextIcon: '$vuetify.icons.next_page'}"
            :loading="loading"
        >
        </v-data-table>

    </div>
</template>

<script>
import http from '../api/http';

export default {
    data() {
        return {
            totalLength: 0,
            headers: [
                { text: "ID", value: "id", sortable: false},
                { text: "订单编号", value: "trade_no", sortable: false},
                { text: "产品", value: "product_id", sortable: false},
                { text: "支付金额", value: "total_amount", sortable: false},
                { text: "支付时间", value: "gmt_payment", sortable: false},
            ],
            options: {},
            dataList: [],
			snackbar_msg: '',
            loading: false
        };
    },
    computed: {

    },
    watch: {
        options: {
            handler () {
                this.getDataFromApi()
            },
            deep: true,
        },
    },
    methods: {
        async getDataFromApi() {
            this.loading = true
            const { page,itemsPerPage } = this.options
            const params = {
                "page": page,
                "limit": itemsPerPage
            }
            
            try {
                const response = await http.get('/alipay/list', {params})
                this.loading = false
      
                let data = response.data.data
                this.totalLength = data.total
                
                for (var i = 0; i < data.items.length; i++) {
                    let product_id = data.items[i].product_id 
                    if (product_id == '1m' || product_id == 'wx_1m' || product_id == 'com.nawang.bigbearvpn.1m' || product_id == 'com.nawang.bigbearvpn_30d'){
                        if (product_id == 'com.nawang.bigbearvpn.1m' || product_id == 'com.nawang.bigbearvpn_30d') {
                            data.items[i].total_amount = "≈ 2.99 USD"
                        }

                        data.items[i].product_id = '1个月VIP'
                    }else if (product_id == '3m' || product_id == 'wx_3m'){
                        data.items[i].product_id = '3个月VIP'
                    }else if (product_id == '12m' || product_id == 'wx_12m'){
                        data.items[i].product_id = '12个月VIP'
                    }
                }

                this.dataList = data.items
            } catch (error) {
                this.loading = false
                this.$root.$emit('show-snackbar', {
                text: error.response.data.msg
            })

            }
        }
    }
}
</script>